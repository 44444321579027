import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OpenPositionComponent } from './components/open-position/open-position.component';
import { CodeCraftComponent } from './components/codecraft-specialist/codecraft-specialist.component';

const routes: Routes = [
  {
    path: '',
    component: OpenPositionComponent,
  },
  {
    path: 'codecraft-specialist/:id',
    component: CodeCraftComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpenPositionRoutingModule {}

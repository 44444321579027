import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Observable, tap } from 'rxjs';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { OpenPositionService } from 'src/app/customer-portal/modules/open-position/services/open-position.service';

@Component({
  selector: 'app-dialog-admin-filter',
  templateUrl: './filter-open-position.component.html',
})
export class FilterAdminOpenPositionSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  form!: FormGroup;
  search: string = '';
  accountID: string = '';
  items = ['Accounts', 'Cost Center', 'Experience Range'];
  expandedIndex = 0;
  costCenters: any[] = [];
  experienceRange: any[] = [];
  projects: any[] = [];
  totalRecords: number = 0;
  selectedCostCenters: string[] = [];
  selectedExperienceRange: string[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  sortBy: 'modifiedAt' = 'modifiedAt';
  selectedAccounts: string[] = [];
  orderBy: 'ASC' | 'DESC' = 'ASC';
  accounts: any[] = [];
  loading: boolean = false;

  selectedAccountsMap: { [key: string]: boolean } = {};
  selectedExperienceRangeMap: { [key: string]: boolean } = {};
  selectedCostCentersMap: { [key: string]: boolean } = {};

  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private costCenterService: CostCenterService,
    private accountService: AccountService,
    private openPositionService: OpenPositionService
  ) {}

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.accountID = user?.associatedAccount?.accountID;

    this.loadCostCenters().subscribe();
    this.loadExperienceRange().subscribe();
    this.loadAccounts().subscribe();
  }

  loadExperienceRange(): Observable<any> {
    return this.openPositionService.getExperianceRange().pipe(
      tap((response: any) => {
        this.experienceRange = response.data.records;
      })
    );
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostList(0, MAX_LIMIT, '', 'createdAt', 'ASC', this.accountID)
      .pipe(
        tap((response: any) => {
          this.costCenters = response.data.records;
        })
      );
  }

  loadAccounts(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.accountService
      .getAccountList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(
        tap((response: any) => {
          this.accounts = response.data.records;
          this.updateAccountCheckboxes();
        })
      );
  }

  updateAccountCheckboxes(): void {
    this.selectedAccounts.forEach((accountID) => {
      this.selectedAccountsMap[accountID] = true;
    });
  }

  hasSelectedFilters(): boolean {
    return (
      this.selectedCostCenters.length > 0 ||
      this.selectedAccounts.length > 0 ||
      this.selectedExperienceRange.length > 0
    );
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedAccounts: this.selectedAccounts,
        selectedCostCenters: this.selectedCostCenters,
        selectedExperienceRange: this.selectedExperienceRange,
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    // Clear the arrays holding the selected filters
    this.selectedAccounts = [];
    this.selectedCostCenters = [];
    this.selectedExperienceRange = [];
  
    // Reset the boolean maps controlling the checkbox states
    this.selectedAccountsMap = {};
    this.selectedCostCentersMap = {};
    this.selectedExperienceRangeMap = {};
  
    // Emit the filters so the parent component can act on the cleared state
    this.filtersApplied.emit({
      selectedAccounts: this.selectedAccounts,
      selectedCostCenters: this.selectedCostCenters,
      selectedExperienceRange: this.selectedExperienceRange,
    });
  
    // Optionally, close the filter drawer
    this.isOpen = false;
  }
  

  // Handlers for checkbox changes
  onAccountChange(event: any, account: any): void {
    if (event.checked) {
      this.selectedAccounts.push(account.accountID);
    } else {
      const index = this.selectedAccounts.indexOf(account.accountID);
      if (index > -1) {
        this.selectedAccounts.splice(index, 1);
      }
    }
  }

  onCostCenterChange(event: any, center: any): void {
    if (event.checked) {
      this.selectedCostCenters.push(center.cc_cost_centerID);
    } else {
      const index = this.selectedCostCenters.indexOf(center.cc_cost_centerID);
      if (index > -1) {
        this.selectedCostCenters.splice(index, 1);
      }
    }
  }

  onExperienceRangeChange(event: any, range: string): void {
    if (event.checked) {
      this.selectedExperienceRange.push(range);
    } else {
      const index = this.selectedExperienceRange.indexOf(range);
      if (index > -1) {
        this.selectedExperienceRange.splice(index, 1);
      }
    }
  }

  toggleCostCenterCheckbox(event: any, center: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleExperienceRangeCheckbox(event: any, range: string) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleAccountCheckbox(event: any, account: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }
}

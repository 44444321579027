<div class="main-content body-bg listing-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text mb-1">CodeCraft Specialist</h4>
        <sft-button [label]="
            'Resources under queue
            '
          " [color]="'primary'" className="grey-text-btn textXS p-0" [variant]="'default'" [disable]="false"
          [withIcon]="true" [icon]="'keyboard_backspace'" [disableRipple]="false" [size]="'small'"
          (buttonClick)="goBack()"></sft-button>
      </div>
      <div class="d-flex align-center">
        <sft-button [label]="'(View job details)'" [className]="'font-italic mat-body-1 text-blue-button  mr-2'"
          [color]="'primary'" [variant]="'default'" [disable]="false" [icon]="'home'" [disableRipple]="false"
          [size]="'medium'" (buttonClick)="openViewJobDrawer()"></sft-button>

        <mat-form-field appearance="outline" class="search-input" [formGroup]="form">
          <input type="text" matInput placeholder="Search" formControlName="searchQuery" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="d-flex mr-2 justify-between header-info-wrapper">
          <button mat-button class="outline-button" (click)="openFilterDrawer()">
            <img src="/images/filter.png" alt="" class="mr-2" />
            <div class="d-flex align-center">
              <span class="mr-2">Filter</span>
              <div *ngIf="isFilterApplied" class="active-circle"></div>
            </div>
          </button>
        </div>
        <sft-button [style.cursor]="!canManageResources ? 'not-allowed' : 'pointer'" [disable]="!canManageResources"
          [matTooltip]="
            canManageResources
              ? ''
              : 'You do not have permission to add candidate'
          " [label]="'Add Candidate'" [color]="'primary'" [variant]="'flat'" [icon]="'home'" [size]="'xl'"
          (buttonClick)="openAddDialog()"></sft-button>
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!loading && candidateList.length === 0" class="required-star" style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 191px);
        font-size: 20px;
      ">
      No candidates found!
    </div>
    <div class="body-bg listing-content overflow-auto craft-listing cost-listing-wrapper"
      *ngIf="!loading && candidateList.length > 0">
      <table mat-table matSort [dataSource]="candidateList" class="custom-action-table"
        (matSortChange)="onCandidateListSort($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header="name" *matHeaderCellDef class="name-info">
            Name
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-center">
              <div class="table-avatar mr-2" style="position: relative">
                <ng-container *ngIf="element.logo; else defaultLogo">
                  <img src="/images/profile.png" alt="profile" style="border-radius: 50%" />
                </ng-container>
                <ng-template #defaultLogo>
                  <div class="table-avatar mr-2" style="
                      background-color: #005F8E;
                      color: #fff;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    ">
                    {{ getDefaultPhotoText(element.cd_name) }}
                  </div>
                </ng-template>
              </div>
              <span class="grey-text name-col table-ellipse" title="{{ element.cd_name || '--' }}">{{ element.cd_name |
                truncate : 10 || "--" }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header="email" *matHeaderCellDef class="email">
            Email Address
          </th>
          <td mat-cell *matCellDef="let element" class="grey-text table-ellipse email-col"
            title="{{ element.cd_email || '--' }}">
            {{ element.cd_email | truncateEmail : 25 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="notice">
          <th mat-header-cell mat-sort-header="noticePeriod" *matHeaderCellDef class="notice-period">
            Notice Period (Days)
          </th>
          <td mat-cell *matCellDef="let element" class="grey-text">
            {{ element.cd_notice_period || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="resume">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="resume-info">
            Resume
          </th>
          <td mat-cell *matCellDef="let element" class="grey-text">
            <div class="d-flex align-center resumne-icon">
              <span class="material-symbols-outlined mr-2" (click)="downloadResume(element.cd_resume)"
                style="cursor: pointer">
                download
              </span>
              <p class="text-info">{{ element.cd_resume || "--" }}</p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="application">
          <th mat-header-cell mat-sort-header="modifiedAt" *matHeaderCellDef class="application-date">
            Application Date
          </th>
          <td mat-cell *matCellDef="let element" class="grey-text">
            {{ element.cd_created_at | date : "dd MMM, yyyy" : "UTC" || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="status-info">
            Status
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="isStatusScreening(element.cd_status)"
              class="success-text success-light-bg w-fit px-2 table-chip">
              {{ getFormattedStatus(element.cd_status) }}
            </div>

            <div *ngIf="isStatusSelected(element.cd_status)"
              class="warning-light-bg warning-text w-fit px-2 table-chip">
              {{ getFormattedStatus(element.cd_status) }}
            </div>

            <div *ngIf="isStatusInterview(element.cd_status)" class="info-text info-light-bg w-fit px-2 table-chip">
              {{ getFormattedStatus(element.cd_status) }}
            </div>

            <div *ngIf="isStatusRejected(element.cd_status)" class="error-text error-light-bg w-fit px-2 table-chip">
              {{ getFormattedStatus(element.cd_status) }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center" style="cursor: pointer">
            <mat-icon [matMenuTriggerFor]="menuEdit" (click)="setElement(element)">
              more_vert
            </mat-icon>
          </td>
          <mat-menu #menuEdit="matMenu" class="action-info">
            <button [style.cursor]="!canManageResources ? 'not-allowed' : 'pointer'" mat-menu-item
              (click)="openEditDialog(element)" [disabled]="!canManageResources" [matTooltip]="
                canManageResources
                  ? ''
                  : 'You do not have permission to edit candidate'
              ">
              Edit
            </button>
            <button mat-menu-item (click)="viewFeedback()">
              View Feedback
            </button>
          </mat-menu>
        </ng-container>
        <tr mat-header-row class="action-table-header-data" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="action-table-body-data" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator *ngIf="candidateList.length > 0" class="action-table-pagination fixed bottom-0 project-listing"
        [length]="totalRecords" [pageSize]="limit" [pageIndex]="pageNumber" [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="onPageChange($event)" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
<app-view-job-detail #viewJobDetail [id]="id"></app-view-job-detail>
<app-dialog-candidate-filter #filter (filtersApplied)="handleFilterApplied($event)"></app-dialog-candidate-filter>
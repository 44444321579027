import { Component, EventEmitter, Output } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';

@Component({
  selector: 'app-dialog-candidate-filter',
  templateUrl: './filter-candidate.component.html',
})
export class FilterCandidateSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  items = ['Status', 'Notice Period'];
  pageNumber: number = 0;
  limit: number = 25;
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  selectedStatus: string[] = [];
  selectedNoticePeriod: string[] = [];

  selectedStatusMap: { [key: string]: boolean } = {};
  selectedNoticePeriodMap: { [key: string]: boolean } = {
    '< 7 days': false,
    '7 - 14 days': false,
    '15 - 30 days': false,
    '30 days': false,
  };

  @Output() filtersApplied = new EventEmitter<any>();

  hasSelectedFilters(): boolean {
    return (
      this.selectedStatus.length > 0 || this.selectedNoticePeriod.length > 0
    );
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedStatus: this.selectedStatus,
        selectedNoticePeriod: this.selectedNoticePeriod,
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    this.selectedStatus = [];
    this.selectedNoticePeriod = [];
    this.selectedStatusMap = {};
    this.selectedNoticePeriodMap = {};
    this.applyFilters();
    this.filtersApplied.emit({});
  }

  onStatusChange(checked: boolean, status: string): void {
    if (checked && !this.selectedStatus.includes(status)) {
      this.selectedStatus.push(status);
    } else if (!checked && this.selectedStatus.includes(status)) {
      const index = this.selectedStatus.indexOf(status);
      if (index > -1) {
        this.selectedStatus.splice(index, 1);
      }
    }
  }

  onNoticePeriodChange(checked: boolean, noticePeriod: string): void {
    if (checked && !this.selectedNoticePeriod.includes(noticePeriod)) {
      this.selectedNoticePeriod.push(noticePeriod);
    } else if (!checked && this.selectedNoticePeriod.includes(noticePeriod)) {
      const index = this.selectedNoticePeriod.indexOf(noticePeriod);
      if (index > -1) {
        this.selectedNoticePeriod.splice(index, 1);
      }
    }
  }

  toggleStatusCheckbox(event: any, status: string) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  statusMapping: { [key: string]: string } = {
    interview: 'Interview',
    selected: 'Selected',
    screening: 'Screening',
    rejected: 'Rejected',
  };

  toggleNoticePeriodCheckbox(event: any, notice: string) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }
}

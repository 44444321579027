<div class="main-content body-bg listing-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
          Talent Acquisitions
        </h4>
        <p class="medium mat-body-1 text textXS">Open positions</p>
      </div>
      <div class="d-flex align-center">
        <mat-form-field
          appearance="outline"
          class="search-input"
          [formGroup]="form"
        >
          <input
            type="text"
            matInput
            placeholder="Search"
            formControlName="searchQuery"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="d-flex mr-2 justify-between header-info-wrapper">
          <button
            mat-button
            class="outline-button"
            (click)="openFilterDrawer()"
          >
            <img src="/images/filter.png" alt="" class="mr-2" />
            <div class="d-flex align-center">
              <span class="mr-2">Filter</span>
              <div *ngIf="isFilterApplied" class="active-circle"></div>
          </div>
          </button>
        </div>
      </div>
    </div>
    <div class="body-bg listing-content acquisitions-listing overflow-auto">
      <app-loader *ngIf="loading"></app-loader>
      <div
        *ngIf="!loading && openPositionList.length === 0"
        class="required-star"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 200px);
          font-size: 20px;
        "
      >
        No Open Positions found!
      </div>
      <div
        class="overflow-auto cost-listing-wrapper"
        *ngIf="!loading && openPositionList.length > 0"
      >
        <table
          mat-table
          [dataSource]="openPositionList"
          class="custom-action-table"
          matSort
          (matSortChange)="onOpenPositionListSort($event)"
          style="cursor: pointer"
        >
          <ng-container matColumnDef="account">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text textS type-info"
              mat-sort-header="accountName"
            >
              Account
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [routerLink]="'codecraft-specialist/' + element.op_positionID"
              title="{{ element.ac_name }}"
            >
              <div class="d-flex align-center">
                <span class="grey-text textS account-name">{{
                  element.ac_name  || "--"
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text textS title"
              mat-sort-header
            >
              Role
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="grey-text textS designation account-name"
              [routerLink]="'codecraft-specialist/' + element.op_positionID"
              title=" {{ element.op_role }}"
            >
              {{ element.op_role  || "--" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="costCenter">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text textS type-info"
              mat-sort-header="centerName"
            >
              Cost
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="grey-text textS account-name"
              [routerLink]="'codecraft-specialist/' + element.op_positionID"
              title=" {{ element.cc_center_name }}"
            >
              {{ element.cc_center_name || "--" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="range">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text textS experience"
              mat-sort-header="experienceRange"
            >
              Experience Range
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="grey-text textS designation table-ellipse"
              [routerLink]="'codecraft-specialist/' + element.op_positionID"
              title="{{ element.op_experience_range }}"
            >
              {{ element.op_experience_range }}
            </td>
          </ng-container>
          <ng-container matColumnDef="startDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text date-info"
              mat-sort-header
            >
              Preferred Start Date
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="grey-text textS"
              [routerLink]="'codecraft-specialist/' + element.op_positionID"
            >
              {{ element.op_start_date | date : "dd MMM, yyyy" : "UTC" || "--" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="noOfCandidate">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="text textS candidate"
              mat-sort-header="noOfResource"
            >
              No. of Candidate
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="grey-text textS candidate-col" 
              [routerLink]="'codecraft-specialist/' + element.op_positionID"
            >
              {{ element.op_no_of_resource }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="status-info"
              mat-sort-header="status"
            >
              Status
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                *ngIf="isStatusClosed(element.op_status)"
                class="closed-text-color grey-text w-fit px-2 table-chip"
              >
                {{ getFormattedStatus(element.op_status) }}
              </div>

              <div
                *ngIf="isStatusInProgress(element.op_status)"
                class="warning-light-bg warning-text w-fit px-2 table-chip"
              >
                {{ getFormattedStatus(element.op_status) }}
              </div>

              <div
                *ngIf="isStatusNew(element.op_status)"
                class="info-text info-light-bg w-fit px-2 table-chip"
              >
                {{ getFormattedStatus(element.op_status) }}
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            class="action-table-header-data"
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
            mat-row
            class="action-table-body-data"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </div>

      <mat-paginator
        *ngIf="!loading && openPositionList.length > 0"
        class="action-table-pagination w-full fixed bottom-0 customer-position"
        [length]="totalRecords"
        [pageSize]="limit"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="onPageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<app-dialog-admin-filter
  #filter
  (filtersApplied)="handleFilterApplied($event)"
></app-dialog-admin-filter>

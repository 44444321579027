import { NgModule } from '@angular/core';
import { LayoutModule } from '../../core/layouts/layout/layout.module';
import { CommonModule } from '@angular/common';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { OpenPositionComponent } from './components/open-position/open-position.component';
import { OpenPositionRoutingModule } from './open-position-routing.module';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { CodeCraftComponent } from './components/codecraft-specialist/codecraft-specialist.component';
import { ViewAdminJobPopupComponent } from './components/codecraft-specialist/view-job-detail/view-job-detail.component';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { FilterAdminOpenPositionSidebarComponent } from './components/open-position/filter-sidebar/filter-open-position.component';
import { FilterCandidateSidebarComponent } from './components/codecraft-specialist/filter-sidebar/filter-candidate.component';

@NgModule({
  declarations: [
    OpenPositionComponent,
    CodeCraftComponent,
    ViewAdminJobPopupComponent,
    FilterCandidateSidebarComponent,
    FilterAdminOpenPositionSidebarComponent,
  ],
  imports: [
    LayoutModule,
    LoaderComponent,
    CommonModule,
    SidebarModule,
    SftButtonModule,
    OpenPositionRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    OpenPositionRoutingModule,
    SharedModule,
  ],
})
export class OpenPositionModule {}

import { Component, Input } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { OpenPositionService } from 'src/app/customer-portal/modules/open-position/services/open-position.service';

@Component({
  selector: 'app-view-job-detail',
  templateUrl: './view-job-detail.component.html',
  styleUrl: './view-job-detail.component.css',
})
export class ViewAdminJobPopupComponent {
  @Input() id!: string;
  side!: MatDrawerMode;
  isOpen: boolean = false;
  jobDetails: any;
  loading: boolean = false;
  relativeDate: string = '';
  showMore: boolean = false;
  descriptionMaxLength: number = 328;
  isDescriptionLong: boolean = false;

  constructor(private openPositionService: OpenPositionService) {}

  ngOnInit(): void {
    if (this.id) {
      this.viewJobDetails(this.id);
    }
  }

  openJobDetail(): void {
    this.isOpen = !this.isOpen;
  }

  viewJobDetails(jobId: string): void {
    this.openPositionService.getOpenPositionById(jobId).subscribe({
      next: (data: any) => {
        this.jobDetails = data?.data;
        if (
          this.jobDetails?.jobDescription?.length > this.descriptionMaxLength
        ) {
          this.isDescriptionLong = true;
        } else {
          this.isDescriptionLong = false;
        }
      },
      error: (error: any) => {
        console.error('Error fetching job details:', error);
      },
    });
  }

  formatNumber(value: number | null | undefined): string {
    if (value == null) {
      return '';
    }
    return value > 0 && value < 10 ? `0${value}` : value.toString();
  }

  calculateRelativeDate(dateString: string): void {
    const postDate = new Date(dateString);
    const today = new Date();

    postDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const diffTime = Math.abs(today.getTime() - postDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      this.relativeDate = 'Today';
    } else if (diffDays === 1) {
      this.relativeDate = '1 day ago';
    } else {
      this.relativeDate = `${diffDays} days ago`;
    }
  }

  toggleDescription(): void {
    this.showMore = !this.showMore;
  }

  get truncatedDescription(): string {
    return (
      this.jobDetails?.jobDescription?.slice(0, this.descriptionMaxLength) || ''
    );
  }

  checkIfDescriptionNeedsTruncation(): void {
    const description = this.jobDetails?.jobDescription || '';
    this.isDescriptionLong = description.length > this.descriptionMaxLength;
  }

  get displayedDescription(): string {
    if (this.showMore || !this.isDescriptionLong) {
      return this.jobDetails?.jobDescription || '';
    } else {
      return this.truncatedDescription + '...';
    }
  }
}

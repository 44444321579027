<mat-drawer-container
  class="pannel-container resource sidebar resource-modal-wrapper unset h-unset overflow-unset"
  [hasBackdrop]="true"
>
  <mat-drawer
    #drawer
    [mode]="side"
    position="end"
    class="details-popup"
    [opened]="isOpen"
    (openedChange)="isOpen = $event"
  >
    <div class="modal-header d-flex justify-between mb-6">
      <div>
        <h2 class="mat-subtitle-1 mb-0 grey-text">Request Resources Details</h2>
      </div>
      <mat-icon class="pointer" (click)="drawer.toggle()">close</mat-icon>
    </div>
    <div class="sub-heading-wrapper mb-6">
      <h2 class="mat-headline-5 fw-700 grey-text">{{ jobDetails?.role }}</h2>
      <span class="grey-text textS fw-500 d-block mb-6">
        <span *ngIf="jobDetails?.project?.name">{{
          jobDetails?.project?.name
        }}</span>
        <span *ngIf="jobDetails?.project?.name" class="mr-2 ml-2">|</span>
        <span>{{ jobDetails?.experienceRange }}</span>
      </span>
      <table class="table-wrapper details-wrapper mb-6">
        <tbody>
          <tr class="mb-2">
            <th
              class="text-left resource-info grey-text textS mr-2 d-block fw-500"
            >
              Posted
            </th>
            <td class="grey-text textS">
              <span class="mr-2">:</span>
              {{ relativeDate }}
            </td>
          </tr>
          <tr class="mb-2">
            <th
              class="text-left resource-info grey-text textS mr-2 d-block fw-500"
            >
              Applicants
            </th>
            <td class="grey-text textS">
              <span class="mr-2">:</span>
              {{ jobDetails?.candidates.length }}
            </td>
          </tr>
          <tr class="mb-2">
            <th
              class="text-left resource-info grey-text textS mr-2 d-block fw-500"
            >
              Preferred Start Date
            </th>
            <td class="grey-text textS">
              <span class="mr-2">:</span>
              {{ jobDetails?.startDate | date : "dd, MMM yyyy" }}
            </td>
          </tr>
          <tr class="mb-2">
            <th
              class="text-left resource-info grey-text textS mr-2 d-block fw-500"
            >
              Number of Resources
            </th>
            <td class="grey-text textS">
              <span class="mr-2">:</span>
              {{ formatNumber(jobDetails?.noOfResource) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content-wrapper mb-6">
      <h3 class="mb-4 grey-text mat-subtitle-2 fw-500">Job description</h3>
      <div class="textS grey-text description">
        <div [innerHTML]="displayedDescription | trustHtml"></div>
        <p
          *ngIf="isDescriptionLong"
          class="read-more-btn"
          (click)="toggleDescription()"
        >
          {{ showMore ? "Read Less" : "Read More" }}
        </p>
      </div>
    </div>
    <div class="content-wrapper mb-6">
      <h3 class="mb-4 grey-text mat-subtitle-2 fw-500">Responsibilities</h3>
      <ol>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Collaborate with product managers to design and implement
          user-friendly interfaces for platforms and applications.
        </li>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Create wireframes, prototypes, and user flow diagrams to communicate
          design concepts and ideas.
        </li>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Develop and maintain front-end code using HTML, CSS, JavaScript, and
          other UI tools and technologies.
        </li>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Test user interfaces for functionality, usability, and cross-platform
          compatibility
        </li>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Optimize UI design for better user engagement, conversion rates, and
          task completion.
        </li>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Stay up to date with emerging UI trends and technologies and
          incorporate them into design solutions.
        </li>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Work with other teams, such as back-end developers and graphic
          designers, to ensure a seamless user experience.
        </li>
        <li class="list-disc mb-2 grey-text textS ml-5">
          Understanding the company's design system and utilizing the structure
          to create consistent UI/UX within and across products..
        </li>
      </ol>
    </div>
  </mat-drawer>
  <div class="overlay" *ngIf="isOpen" (click)="openJobDetail()"></div>
</mat-drawer-container>
